import { request } from 'utils';
import {message} from "antd";
import {PAGE_SIZE, SUCCESS_CODE} from "../configs";
export const fetchCommentList = async (page: number, userName?: string, content?: string) => {
  try {
    const res = await request.get(`/comment/comment-list?page=${page}&pageSize=${PAGE_SIZE}&content=${content || ''}&userName=${userName || ''}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchCommentDelete = async (id: number) => {
  try {
    const res = await request.post('/comment/delete', {
      id,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
export const fetchCommentAudit = async (id: number, pass: boolean) => {
  try {
    const res = await request.post('/comment/audit', {
      id,
      pass,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
