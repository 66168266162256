import React, {useState} from 'react';
import {Button, Image, Modal, Space} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import EditConfigDrawer from './components/EditConfigDrawer';
import {fetchProConfigList, fetchProConfigDelete} from "../../services/config";
import {ColumnsType} from "antd/es/table";
import BlankRight from "../../components/BlankRight";
import {NConfig} from "../../types/config";
import SimpleTable, {ISimpleTableRef} from "../../components/SimpleTable";
import {baseRender, imageRender, toolTipRender} from "../../components/Render";

const ProConfig = () => {
  const tableRef = React.createRef<ISimpleTableRef>();
  const [currentUpdate, setCurrentUpdate] = useState<NConfig.IProConfigItem | true | null>(null);

  const fetchList = async () => {
    const data = await fetchProConfigList();
    return {
      list: data,
      count: data.length,
    }
  };

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };



  const handleDelUser = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchProConfigDelete(id);
        res  && await onRefreshList();
      },
      onCancel: () => {},
    });
  };

  const columns: ColumnsType<NConfig.IProConfigItem> = [
    {
      title: '配置key',
      dataIndex: 'name',
      render: baseRender,
    },
    {
      title: '配置值',
      dataIndex: 'value',
      render: baseRender,
    },
    {
      title: '图片',
      dataIndex: 'image',
      render: imageRender,
    },
    {
      title: '地址',
      dataIndex: 'url',
      render: toolTipRender,
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => setCurrentUpdate(record)}>
            编辑
          </Button>
          <Button danger type='primary' onClick={() => handleDelUser(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <BlankRight>
        <Space>
          <Button type='primary' onClick={() => onRefreshList()}>刷新数据</Button>
          <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加数据</Button>
        </Space>
      </BlankRight>
      <SimpleTable columns={columns} fetchList={fetchList} ref={tableRef} pagination={false}/>
      <EditConfigDrawer
        info={currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={() => onRefreshList()}
      ></EditConfigDrawer>
    </PageBox>
  );
};

export default ProConfig;
