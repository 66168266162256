import React, {memo, PropsWithChildren} from 'react';

import {Button, Drawer, Form, Input, Modal, Space} from 'antd';
import {fetchAdminCreate} from "../../../services/config";
import {copyText} from "../../../utils";

interface IProps {
  visible: boolean;
  onClose: Function;
  onRefresh: Function;
}

export default memo(({ visible, onClose, onRefresh }: PropsWithChildren<IProps>) => {
  const [form] = Form.useForm();
  const onInnerClose = () => {
    form.setFieldsValue({
      username: '',
    });
    onClose();
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      const res = await fetchAdminCreate(values.username);
      if (res) {
        onRefresh();
        onInnerClose();
        await copyText(`${values.username}\n${res.password}`, '创建成功，');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Drawer
      title='编辑配置'
      width={600}
      onClose={onInnerClose}
      destroyOnClose
      maskClosable={false}
      open={visible}
      footer={(
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Space>
            <Button onClick={onInnerClose}>取消</Button>
            <Button htmlType='submit' onClick={onSubmit} type='primary'>
              确定
            </Button>
          </Space>
        </div>
      )}
    >
      <Form
        form={form}
        labelWrap
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        layout='horizontal'
      >
        <Form.Item label='用户名' name='username' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
      </Form>
    </Drawer>
  );
});
