import React, { useCallback, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Breadcrumb } from 'antd';
import classnames from 'classnames';
import { layout } from 'configs/global';
import { IMenuItem } from 'configs/menu';
import { mapedMenu } from 'utils/layout';

import Style from './index.module.less';

const LBreadcrumb = (props: HTMLAttributes<{}>) => {
  const { pathname } = useLocation();
  const { showBreadcrumb } = layout;
  const selectedMap = mapedMenu.get(pathname);

  const linkTo = useCallback((p: IMenuItem) => {
    const { path, children } = p;
    if (path) return path;

    const defaultMenu = children?.find((c) => c.path);
    return defaultMenu?.path;
  }, []);

  if (showBreadcrumb && selectedMap) {
    const { current, path } = selectedMap;
    if (path.length < 1) return null;

    const wholeBreads = [...path, current];
    return (
      <Breadcrumb className={classnames(Style.breadcrumbBox, props.className)}>
        {wholeBreads.map((p) => (
          <Breadcrumb.Item key={p.key}>
            <Link to={linkTo(p) as string}>{p.label}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
  return null;
};

export default React.memo(LBreadcrumb);
