
type TParams = Record<string, string | number | boolean>;

export const report = (eventCode: string, params: TParams) => {
  // console.log(eventCode, params);
};

export const reportDirect = (eventCode: string, params: TParams) => {
  report(eventCode, params);
};
