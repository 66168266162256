import React, {useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Space} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import EditUserDrawer from './components/EditUserDrawer';
import {ColumnsType} from "antd/es/table";
import BlankRight from "../../components/BlankRight";
import {NConfig} from "../../types/config";
import SimpleTable, { ISimpleTableRef } from "../../components/SimpleTable";
import {fetchUserDel, fetchUserList} from "../../services/user";
import {baseRender, imageRender} from "../../components/Render";

const InnerUser = () => {
  const [form] = Form.useForm();
  const tableRef = React.createRef<ISimpleTableRef>();
  const [currentUpdate, setCurrentUpdate] = useState<NConfig.IPageConfigItem | true | null>(null);

  const fetchList = async (page: number = 1) => {
    const { username = '' } = form.getFieldsValue();
    const { list, count } = await fetchUserList(page, username);
    return {list, count};
  };

  const handleDel = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchUserDel(id);
        res  && onRefreshList();
      },
      onCancel: () => {},
    })
  };

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const columns: ColumnsType<NConfig.IPageConfigItem> = [
    {
      title: '用户名',
      dataIndex: 'userName',
      render: baseRender,
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      render: baseRender,
    },
    {
      title: '头像',
      dataIndex: 'avatar',
      render: imageRender,
    },
    {
      title: '部门',
      dataIndex: 'dept',
      render: baseRender,
    },
    {
      title: '职位',
      dataIndex: 'title',
      render: baseRender,
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => setCurrentUpdate(record)}>
            编辑
          </Button>
          <Button danger type='primary' onClick={() => handleDel(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <Form form={form} name='advanced_search' style={{ background: '#fff', padding: '24px'}} onFinish={onRefreshList}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label='用户名' name='username'>
              <Input placeholder='请输入'></Input>
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: 'right' }}>
          <Space size='small'>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                onRefreshList();
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      </Form>
      <BlankRight>
        <Space>
          <Button type='primary' onClick={() => onRefreshList()}>刷新数据</Button>
          <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加数据</Button>
        </Space>
      </BlankRight>
      <SimpleTable
        ref={tableRef}
        columns={columns}
        fetchList={fetchList}
      />
      <EditUserDrawer
        info={currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={onRefreshList}
      ></EditUserDrawer>
    </PageBox>
  );
};

export default InnerUser;
