import React, {useEffect} from 'react';
import { Button, Form, Input, Card } from 'antd';
import Breadcrumb from 'components/Breadcrumb';
import style from './index.module.less';
import {fetchLoginApi} from "../../services/login";
import {useAppSelector} from "../../modules/store";

const { useForm } = Form;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const LoginPage = () => {
  const globalState = useAppSelector(state => state.global);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form] = useForm();

  useEffect(() => {
    if (globalState.isLogin) {
      location.href = '/';
    }
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    await fetchLoginApi(values);
    setLoading(false);
  };

  return (
    <>
      <Breadcrumb />
      <Card className={style.loginCard}>
        <Form {...layout} form={form} name='loginForm' onFinish={handleSubmit}>
          <Form.Item name='username' label='账号' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='password' label='密码' rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button id='btnSubmit' type='primary' htmlType='submit' loading={loading}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default React.memo(LoginPage);
