import { request } from 'utils';
import {message} from "antd";
import {SUCCESS_CODE} from "../configs";
export const fetchSubCategoryList = async (type: string) => {
  try {
    const res = await request.get(`/sub-category/list?type=${type}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchSubCategoryCreate = async (values: any) => {
  try {
    const res = await request.post('/sub-category/create', values);
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchSubCategoryDelete = async (id: number) => {
  try {
    const res = await request.post('/sub-category/delete', {
      id,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchSubCategoryModify = async (id: number, values: any) => {
  try {
    const res = await request.post('/sub-category/update', {
      id,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
