import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Layout, Spin } from 'antd';
import { layout, project } from 'configs';
import { useAppSelector, useAppDispatch } from 'modules/store';
import { fetchAuth } from 'modules/global';
import { reportDirect } from 'utils';
import Style from './layout.module.less';
import Container from "./Container";

const setTheme = (theme: string) => {
  document.documentElement.setAttribute('theme', theme);
};

const LLayout = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const globalState = useAppSelector((state) => state.global);

  useEffect(() => {
    document.title = project.title;
    setTheme(layout.theme);
    dispatch(fetchAuth());
  }, []);

  useEffect(() => {
    reportDirect('page_exp', { pathname });
  }, [pathname]);

  return (
    <Layout className={Style.proLayout}>
      {globalState.loading ? <Spin className={Style.pageLoading} /> : <Container />}
    </Layout>
  );
};

export default React.memo(LLayout);
