export const PAGE_SIZE = 20;
export const SUCCESS_CODE = 0;

export enum CommonConfig {
  bingliji = 'bingliji', // 病例集
  calendar = 'calendar', // 会议日历
  category = 'category', // 主分类
  method = 'method', // 使用方法
  series = 'series', // 课程系列
}

export enum ContentType {
  video = 'video',
  pdf = 'pdf',
  article = 'article',
  weixin = 'weixin',
}

export const CONTENT_TYPE  = [
  {
    label: '视频',
    value: ContentType.video,
  },
  {
    label: 'PDF',
    value: ContentType.pdf,
  },
  {
    label: '文章',
    value: ContentType.article,
  },
  {
    label: '微信公众号文章',
    value: ContentType.weixin,
  },
];
export const AUDIT_STATUS  = [
  {
    label: '待审核',
    value: '0',
  },
  {
    label: '审核通过',
    value: '1',
  },
  {
    label: '审核拒绝',
    value: '2',
  },
];

export const URL_TYPE_OPTIONS = [
  {
    label: '外链',
    value: 'outLink',
  },
  {
    label: '其他小程序',
    value: 'outMini',
  },
  {
    label: '小程序内Tab',
    value: 'miniTab',
  },
  {
    label: '小程序内页面',
    value: 'miniPage',
  },
];
