import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {SUCCESS_CODE} from "../configs";

interface IAxiosInstance extends AxiosInstance {
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

export const request: IAxiosInstance = axios.create({
  timeout: 10000,
  withCredentials: true,
  baseURL: '/pro-api/cms-api'
});

request.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data && data.code === SUCCESS_CODE) {
      return data;
    }

    return Promise.reject(data);
  },
  async (e) => Promise.reject(e),
);
