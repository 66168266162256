import { request } from 'utils';
import {message} from "antd";
import {PAGE_SIZE, SUCCESS_CODE} from "../configs";

export const fetchCommonConfigList = async (type: string) => {
  try {
    const res = await request.get(`/common-config/list?type=${type}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchCommonConfigCreate = async (type: string, values: any) => {
  try {
    const res = await request.post('/common-config/create', {
      type,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchCommonConfigDelete = async (id: number, type: string) => {
  try {
    const res = await request.post('/common-config/delete', {
      id,
      type,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchCommonConfigModify = async (id: number, type: string,  values: any) => {
  try {
    const res = await request.post('/common-config/update', {
      id,
      type,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
