import React, { memo } from 'react';
import {Layout, LayoutProps, Dropdown, Space, MenuProps} from 'antd';
import {ExclamationCircleOutlined, LogoutOutlined} from '@ant-design/icons';
import classnames from 'classnames';
import { useAppSelector } from 'modules/store';
import { selectGlobal } from 'modules/global';
import Style from './layout.module.less';
import {fetchLogoutApi} from "../services/login";
import {Link} from "react-router-dom";
import * as routerPath from "../configs/routerPath";

const { Header } = Layout;

interface LHeaderProps extends LayoutProps {
  hideLogo?: boolean;
}

const User = memo(() => {
  const globalState = useAppSelector(selectGlobal);

  if (!globalState.isLogin) {
    return null;
  }

  const items: MenuProps['items'] = [
    {
      key: 'password',
      icon: <ExclamationCircleOutlined />,
      label: <Link to={routerPath.password}>修改密码</Link>,
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: <span onClick={() => fetchLogoutApi()}>退出登录</span>,
    },
  ];

  return (
    <Dropdown menu={{ items: items}}>
      <Space size='small' align='center'>
        {globalState.name && <span>{globalState.name}</span>}
      </Space>
    </Dropdown>
  );
});


export const Logo = memo(() => (
  <div
    className={classnames(
      Style.logo,
      Style.largeLogo,
      Style.leftLogo,
    )}
  ></div>
));


const LHeader = ({className, children, hideLogo, ...otherProps}: React.PropsWithChildren<LHeaderProps>) => (
  <Header className={Style.proHeader} {...otherProps}>
    <div>{!hideLogo && <Logo />}</div>

    <Space size='middle' align='center'>
      {children}
      <User />
    </Space>
  </Header>
);

export default memo(LHeader);
