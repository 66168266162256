import React, {memo, PropsWithChildren, useEffect} from 'react';

import {Button, Drawer, Form, Input, Space} from 'antd';
import UploadSingle from "../../../components/Form/UploadSingle";
import {
  fetchConfigCreate,
  fetchConfigModify,
} from "../../../services/config";
import {NConfig} from "../../../types/config";
import FormBanner from "../../../components/Form/FormBanner";

interface IProps {
  info: NConfig.IPageConfigItem | true | null;
  onClose: Function;
  onRefresh: Function;
}

export default memo(({ info, onClose, onRefresh }: PropsWithChildren<IProps>) => {
  const [form] = Form.useForm();
  const onInnerClose = () => {
    form.setFieldsValue({
      name: '',
      value: '',
      url: '',
      image: '',
    });
    onClose();
  };

  useEffect(() => {
    if (info && typeof info === 'object') {
      form.setFieldsValue(info);
    }
  }, [info]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      let res;
      if (typeof info === 'object' && info && info.id) {
        res = await fetchConfigModify(info.id, values);
      } else {
        res = await fetchConfigCreate(values);
      }
      if (res) {
        await onRefresh();
        onInnerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  let UserInfo = null;
  if (info) {
    UserInfo = (
      <Form
        form={form}
        labelWrap
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        layout='horizontal'
      >
        <Form.Item label='页面标识' name='pageKey' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='页面名称' name='pageName' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='页面封面' name='pageCover'>
          <UploadSingle />
        </Form.Item>
        <Form.Item label='分享标题' name='shareTitle'>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='分享封面' name='shareCover'>
          <UploadSingle />
        </Form.Item>
        <Form.Item label='排序方式' name='orderType'>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='备注' name='remark'>
          <Input.TextArea placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='轮播' name='banner'>
          <FormBanner />
        </Form.Item>
        <Form.Item label='金刚' name='kingKong'>
          <FormBanner />
        </Form.Item>
      </Form>
    );
  }

  return (
    <Drawer
      title='编辑配置'
      width={1200}
      onClose={onInnerClose}
      destroyOnClose
      maskClosable={false}
      open={!!info}
      footer={(
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Space>
            <Button onClick={onInnerClose}>取消</Button>
            <Button htmlType='submit' onClick={onSubmit} type='primary'>
              确定
            </Button>
          </Space>
        </div>
      )}
    >
      {UserInfo}
    </Drawer>
  );
});
