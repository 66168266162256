import {PAGE_SIZE} from "../../configs";
import {Table} from "antd";
import React, {useEffect, useImperativeHandle, useState} from "react";
import {ColumnsType} from "antd/es/table";

interface IProps {
  rowKey?: string;
  initFetch?: boolean;
  columns: ColumnsType<any>;
  pagination?: boolean;
  fetchList: (page: number) => Promise<{list: any[], count: number}>;
}

export interface ISimpleTableRef {
  refreshList: () => void;
}

// function SimpleTable<T extends object = any> (props: IProps<T>) {
const SimpleTable = React.forwardRef((props: IProps, ref: React.Ref<ISimpleTableRef>) =>{
  const {fetchList, columns, initFetch = true, rowKey='id', pagination = true } = props;
  const [data, setData] = useState({
    list: [] as any[],
    count: 0,
    loading: false,
    current: 1,
  });

  const loadData = async (page: number = 1) => {
    setData((old) => ({
      ...old,
      loading: true,
    }));
    const {list, count} = await fetchList(page);
    setData({
      list,
      count,
      loading: false,
      current: page,
    });
  };
  useImperativeHandle(ref, () => ({
    refreshList: () => loadData(),
  }));

  useEffect(() => {
    initFetch && loadData();
  }, []);

  return (
    <Table
      scroll={{x: 'max-content'}}
      loading={data.loading}
      columns={columns}
      dataSource={data.list}
      rowKey={rowKey}
      pagination={pagination ? {
        total: data.count,
        current: data.current,
        pageSize: PAGE_SIZE,
        onChange: (page) => loadData(page),
      } : false}
    />
  )
});

export default SimpleTable;
