import React, {useEffect, useState} from 'react';
import {Button, Modal, Space, Spin, Tabs} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import EditDrawer from './components/EditDrawer';
import {ColumnsType} from "antd/es/table";
import BlankRight from "../../components/BlankRight";
import {NBiz} from "../../types/biz";
import {fetchSubCategoryDelete, fetchSubCategoryList} from "../../services/category";
import {fetchCommonConfigList} from "../../services/commonConfig";
import {CommonConfig} from "../../configs";
import SimpleTable, {ISimpleTableRef} from "../../components/SimpleTable";
import {baseRender, imageRender} from "../../components/Render";

const SubCategoryTable = (props: { type: string }) => {
  const tableRef = React.createRef<ISimpleTableRef>();
  const [currentUpdate, setCurrentUpdate] = useState<NBiz.ISubCategory | true | null>(null);

  const fetchList = async () => {
    const data = await fetchSubCategoryList(props.type);
    return {
      list: data,
      count: data.length,
    }
  };

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const handleDelUser = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchSubCategoryDelete(id);
        res  && await onRefreshList();
      },
      onCancel: () => {},
    });
  };

  const columns: ColumnsType<NBiz.ISubCategory> = [
    {
      title: '名称',
      dataIndex: 'title',
      render: baseRender,
    },
    {
      title: '位置',
      dataIndex: 'position',
      render: baseRender,
    },
    {
      title: '图片',
      dataIndex: 'cover',
      render: imageRender,
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => setCurrentUpdate(record)}>
            编辑
          </Button>
          <Button danger type='primary' onClick={() => handleDelUser(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <PageBox>
      <BlankRight>
        <Space>
          <Button type='primary' onClick={() => onRefreshList()}>刷新数据</Button>
          <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加数据</Button>
        </Space>
      </BlankRight>
      <SimpleTable columns={columns} fetchList={fetchList} ref={tableRef} pagination={false}/>
      <EditDrawer
        type={props.type}
        info={currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={() => onRefreshList()}
      ></EditDrawer>
    </PageBox>
  );
};


const SubCategoryConfig = () => {
  const [list, setList] = useState<NBiz.ICommonConfig[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async () => {
    setLoading(true);
    const data = await fetchCommonConfigList(CommonConfig.category);
    setList(data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <Spin spinning={loading}>
        <Tabs
          style={{ background: '#fff', padding: '20px' }}
          items={list.map((item) => ({
            key: item.enName,
            label: item.name,
            children: <SubCategoryTable type={item.enName} />
          }))}
        />
      </Spin>
    </PageBox>
  );
};

export default SubCategoryConfig;
