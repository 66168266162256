import React, {memo, PropsWithChildren, useEffect} from 'react';
import {Button, Drawer, Form, Input, Select, Space} from 'antd';
import UploadSingle, {UploadAcceptType} from "../../../components/Form/UploadSingle";
import {
  fetchProductModify,
  fetchProductCreate,
} from "../../../services/content";
import {NBiz} from "../../../types/biz";
import {fetchSubCategoryList} from "../../../services/category";
import {DefaultOptionType} from "rc-select/lib/Select";

const getSubCateGoryOptions = async (categoryName: string) => {
  const res = await fetchSubCategoryList(categoryName);
  const list: DefaultOptionType[] = [];
  res.forEach((item: any) => {
    list.push({label: item.title, value: item.id.toString() });
  });
  return list;
};

interface IProps {
  categoryEnName: string;
  info: NBiz.IContentItem | true | null;
  onClose: Function;
  onRefresh: Function;
}

export default memo((props: PropsWithChildren<IProps>) => {
  const { info, onClose, onRefresh, categoryEnName } = props;
  const [form] = Form.useForm<NBiz.IContentItem>();
  const [subCategoryOptions, setSubCategoryOptions] = React.useState<DefaultOptionType[]>([]);
  const onInnerClose = () => {
    form.setFieldsValue({
      title: '',
      cover: '',
      url: '',
    });
    onClose();
  };

  useEffect(() => {
    getSubCateGoryOptions(categoryEnName).then((res) => {
      setSubCategoryOptions(res);
    });
  }, []);

  useEffect(() => {
    if (info && typeof info === 'object') {
      form.setFieldsValue(info);
    }
  }, [info]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      let res;
      if (typeof info === 'object' && info && info.id) {
        res = await fetchProductModify(info.id, values);
      } else {
        res = await fetchProductCreate(values);
      }
      if (res) {
        await onRefresh();
        onInnerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  let UserInfo = null;
  if (info) {
    UserInfo = (
      <Form
        form={form}
        labelWrap
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        layout='horizontal'
      >
        <Form.Item label='标题' name='title' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='封面' name='cover' rules={[{required: true, message: '请输入'}]}>
          <UploadSingle />
        </Form.Item>
        <Form.Item label='产品分类' name='subCategoryId' rules={[{required: true, message: '请输入'}]}>
          <Select placeholder='请输入' options={subCategoryOptions}/>
        </Form.Item>
        <Form.Item label='文件' name='url'>
          <UploadSingle accept={UploadAcceptType.other}/>
        </Form.Item>
      </Form>
    );
  }

  return (
    <Drawer
      title='编辑配置'
      width={1200}
      onClose={onInnerClose}
      destroyOnClose
      maskClosable={false}
      open={!!info}
      footer={(
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Space>
            <Button onClick={onInnerClose}>取消</Button>
            <Button htmlType='submit' onClick={onSubmit} type='primary'>
              确定
            </Button>
          </Space>
        </div>
      )}
    >
      {UserInfo}
    </Drawer>
  );
});
