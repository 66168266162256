import React, {useState} from "react";
import BraftEditor, { EditorState } from 'braft-editor';
import 'braft-editor/dist/index.css';
import {Button, Card, message} from "antd";
import {uploadConfigFile} from "../../services/config";
import {SUCCESS_CODE} from "../../configs";

interface IUpload {
  file: File;
  progress: (progress: number) => void;
  libraryId: string;
  success: (
    res: {
      url: string;
      meta: {
        id: string;
        title: string;
        alt: string;
        loop: boolean;
        autoPlay: boolean;
        controls: boolean;
        poster: string;
      };
    }
  ) => void;
  error: (
    err: {
      msg: string;
    }
  ) => void;
}

interface IProps {
    value?: string;
    onChange?: (value: string) => void;
}

export interface IEditorRef {
  setContent: (content: string) => void;
  getContent: () => string;
}

const FormEditor = React.forwardRef((props: IProps, ref: React.ForwardedRef<IEditorRef>) => {
  const [editorState, setEditorState] = useState({
    content: BraftEditor.createEditorState(props.value),
  });

  React.useImperativeHandle(ref, () => ({
    setContent: (content: string) => {
      setEditorState({
        content: BraftEditor.createEditorState(content),
      });
    },
    getContent: () => editorState.content.toHTML(),
  }));

  const handleContentChange = (value: EditorState) => {
    setEditorState({
      content: value,
    });
    props.onChange?.(value.toHTML());
  };

  const uploadFn = (params: IUpload) => {
    uploadConfigFile(params.file).then(res => {
      const { data, code, msg } = res;
      if (code !== SUCCESS_CODE || !data.url) {
        message.warn(msg);
        params.error({ msg: '上传失败' });
      } else {
        params.success({url: data.url } as any);
      }
    });
  };

  const handleEditorSave = () => {
    props.onChange?.(editorState.content.toHTML());
  };

  return (
    <Card
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>富文本编辑器</div>
          <Button type='primary' onClick={handleEditorSave}>
            保存
          </Button>
        </div>
      }
    >
      {/*@ts-ignore*/}
      <BraftEditor
        value={editorState.content}
        onChange={handleContentChange}
        onSave={handleEditorSave}
        media={{
          uploadFn: uploadFn,
          accepts: {
            video: false,
            audio: false,
          },
        }}
      />
    </Card>
  )
});

export default FormEditor;
