import React, {useState} from 'react';
import {Button, Modal, Space} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import EditDrawer from './components/EditDrawer';
import {ColumnsType} from "antd/es/table";
import BlankRight from "../../components/BlankRight";
import {NBiz} from "../../types/biz";
import {fetchCommonConfigList, fetchCommonConfigDelete} from "../../services/commonConfig";
import {CommonConfig, URL_TYPE_OPTIONS} from "../../configs";
import SimpleTable, {ISimpleTableRef} from "../../components/SimpleTable";
import {baseRender, imageRender} from "../../components/Render";

const CommonConfigPage = (props: { type: string }) => {
  const tableRef = React.createRef<ISimpleTableRef>();
  const [currentUpdate, setCurrentUpdate] = useState<NBiz.ICommonConfig | true | null>(null);

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const fetchList = async () => {
    const data = await fetchCommonConfigList(props.type);
    return {
      list: data,
      count: data.length,
    }
  };

  const handleDel = async (record: NBiz.ICommonConfig) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchCommonConfigDelete(record.id, props.type);
        res  && await onRefreshList();
      },
      onCancel: () => {},
    });
  };

  const columns: ColumnsType<NBiz.ICommonConfig> = [
    {
      title: '展示名称',
      dataIndex: 'name',
      render: baseRender,
    },
    {
      title: '英文名称',
      dataIndex: 'enName',
      render: baseRender,
    },
    {
      title: '位置',
      dataIndex: 'position',
      render: baseRender,
    },
    {
      title: '图片',
      dataIndex: 'image',
      render: imageRender,
    },
    {
      title: '跳转类型',
      dataIndex: 'urlType',
      render: (value) => {
        if (!value) return '--';
        return URL_TYPE_OPTIONS.find((item) => item.value === value)?.label || '--';
      }
    },
    {
      title: '跳转地址',
      dataIndex: 'url',
      render: baseRender,
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => setCurrentUpdate(record)}>
            编辑
          </Button>
          <Button danger type='primary' onClick={() => handleDel(record)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <BlankRight>
        <Space>
          <Button type='primary' onClick={() => onRefreshList()}>刷新数据</Button>
          <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加数据</Button>
        </Space>
      </BlankRight>
      <SimpleTable columns={columns} fetchList={fetchList} ref={tableRef} pagination={false}/>
      <EditDrawer
        type={props.type}
        info={currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={() => onRefreshList()}
      ></EditDrawer>
    </PageBox>
  );
};

export const BingLiJiConfig = () => <CommonConfigPage type={CommonConfig.bingliji} />;
export const CalendarConfig = () => <CommonConfigPage type={CommonConfig.calendar} />;
export const MethodConfig = () => <CommonConfigPage type={CommonConfig.method} />;
export const CategoryConfig = () => <CommonConfigPage type={CommonConfig.category} />;
export const SeriesConfig = () => <CommonConfigPage type={CommonConfig.series} />;
