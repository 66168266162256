import {message, notification} from 'antd';
import { RcFile } from 'antd/lib/upload';
import copy from 'copy-to-clipboard';

export const rejectUndefined = (obj: { [key: string]: any }) => {
  const keys = Object.keys(obj);
  const result: { [key: string]: any } = {};
  keys.forEach((key) => {
    if (obj[key] !== undefined) {
      result[key] = obj[key];
    }
  });
  return result;
};

export const getBase64 = (file: RcFile): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const notify = (info: any = {}) => {
  const result: string[] = [];
  Object.keys(info).forEach((key) => {
    result.push(`${key} = ${typeof info[key] === 'string' ? info[key] : JSON.stringify(info[key])}`);
  });

  notification.open({
    message: '操作记录',
    description: result.join(`\n`),
    style: {
      whiteSpace: 'pre-wrap',
    },
  });
};

export const copyText = async (text: string, tips?: string) => {
  copy(text);
  await message.success(`${tips ?? ''}已复制到剪切板!`);
};

export function getFormattedDate(timeString: string) {
  function addZero(m: number) {
    return m < 10 ? `0${m}` : m;
  }
  const time = new Date(timeString);
  const y = time.getFullYear();
  const M = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const m = time.getMinutes();
  const s = time.getSeconds();
  return `${y}-${addZero(M)}-${addZero(d)} ${addZero(h)}:${addZero(m)}:${addZero(s)}`;
}
