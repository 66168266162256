import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { project } from 'configs';
import { IAuth } from 'modules/global';
import { useAppSelector } from 'modules/store';

export const isAuthRoute = (key: boolean, isAdmin: boolean): boolean =>
  key === isAdmin;

interface IProps extends RouteProps {
  admin?: boolean;
}

const AuthorizedRoute = (props: IProps) => {
  const globalState = useAppSelector((state) => state.global);
  if (project.openAuth && props.admin && !isAuthRoute(props.admin, globalState.isAdmin)) {
    return <Redirect to={{ pathname: '/403' }} />;
  }
  return <Route {...props} />;
};

export default React.memo(AuthorizedRoute);
