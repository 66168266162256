import React, {memo, PropsWithChildren, useEffect} from 'react';

import {Button, Drawer, Form, Input, Select, Space} from 'antd';
import UploadSingle from "../../../components/Form/UploadSingle";
import {fetchCourseModify, fetchCourseCreate } from "../../../services/content";
import {NBiz} from "../../../types/biz";
import {fetchSubCategoryList} from "../../../services/category";
import {DefaultOptionType} from "rc-select/lib/Select";
import FormEditor, { IEditorRef } from "../../../components/Form/FormEditor";
import {fetchCommonConfigList} from "../../../services/commonConfig";
import {CommonConfig} from "../../../configs";

const category = 'dashi';
const getSubCateGoryOptions = async () => {
  const res = await fetchSubCategoryList(category);
  const list: DefaultOptionType[] = [];
  res.forEach((item: any) => {
    list.push({label: item.title, value: item.id.toString() });
  });
  return list;
};

const getSeriesOptions = async () => {
  const res = await fetchCommonConfigList(CommonConfig.series);
  const list: DefaultOptionType[] = [];
  res.forEach((item: any) => {
    list.push({label: item.name, value: item.enName });
  });
  return list;
};

interface IProps {
  info: NBiz.IContentItem | true | null;
  onClose: Function;
  onRefresh: Function;
}

export default memo((props: PropsWithChildren<IProps>) => {
  const { info, onClose, onRefresh } = props;
  const editRef = React.createRef<IEditorRef>();
  const [subCategoryOptions, setSubCategoryOptions] = React.useState<DefaultOptionType[]>([]);
  const [seriesOptions, setSeriesOptions] = React.useState<DefaultOptionType[]>([]);
  const [form] = Form.useForm<NBiz.IContentItem>();
  const onInnerClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    getSubCateGoryOptions().then((options) => {
      setSubCategoryOptions(options);
    });
    getSeriesOptions().then((options) => {
      setSeriesOptions(options);
    });
  }, []);

  useEffect(() => {
    if (info && typeof info === 'object') {
      form.setFieldsValue(info);
      editRef.current?.setContent(info.content);
    }
  }, [info]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      const body = {
        ...values,
        category,
      }
      let res;
      if (typeof info === 'object' && info && info.id) {
        res = await fetchCourseModify(info.id, body);
      } else {
        res = await fetchCourseCreate(body);
      }
      if (res) {
        await onRefresh();
        onInnerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  let UserInfo = null;
  if (info) {
    UserInfo = (
      <Form
        form={form}
        labelWrap
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        layout='horizontal'
      >
        <Form.Item label='标题' name='title' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='封面' name='cover' rules={[{required: true, message: '请输入'}]}>
          <UploadSingle />
        </Form.Item>
        <Form.Item label='所属系列' name='series' rules={[{required: true, message: '请输入'}]}>
          <Select placeholder='请输入' options={seriesOptions}/>
        </Form.Item>
        <Form.Item label='所属分类' name='subCategoryId' rules={[{required: true, message: '请输入'}]}>
          <Select placeholder='请输入' options={subCategoryOptions}/>
        </Form.Item>
        <Form.Item label='课程详情' name='content' rules={[{ required: true, message: '请输入' }]}>
          <FormEditor ref={editRef}/>
        </Form.Item>
      </Form>
    );
  }

  return (
    <Drawer
      title='编辑配置'
      width={1200}
      onClose={onInnerClose}
      destroyOnClose
      maskClosable={false}
      open={!!info}
      footer={(
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Space>
            <Button onClick={onInnerClose}>取消</Button>
            <Button htmlType='submit' onClick={onSubmit} type='primary'>
              确定
            </Button>
          </Space>
        </div>
      )}
    >
      {UserInfo}
    </Drawer>
  );
});
