import React, { useState } from 'react';
import { Layout } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { layout } from 'configs';
import { getMenuCollapsed, saveMenuCollapsed } from 'utils';
import Menu from './Menu';
import { Logo } from './Header';
import Style from './layout.module.less';
import {useAppSelector} from "../modules/store";

type TMenuMode = 'vertical' | 'inline';

const { Sider } = Layout;
const { theme } = layout;

const LSider = () => {
  const globalState = useAppSelector((state) => state.global);
  const [collapsed, setCollapsed] = useState(getMenuCollapsed());
  const TriggerIcon = collapsed ? RightOutlined : LeftOutlined;
  const menuMode = collapsed ? 'vertical' : 'inline';

  const setMenuCollapsed = (collapse: boolean) => {
    saveMenuCollapsed(collapse);
    setCollapsed(collapse);
  };

  if (!globalState.isLogin) {
    return null;
  }

  return (
    <Sider
      onCollapse={setMenuCollapsed}
      trigger={
        <div className={Style.triggerIcon}>
          <TriggerIcon />
        </div>
      }
      collapsible
      defaultCollapsed={collapsed}
      theme={theme}
      collapsedWidth={48}
    >
      <Menu collapsed={collapsed} mode={menuMode as TMenuMode} />
    </Sider>
  );
};

export default React.memo(LSider);
