interface ILayout {
  theme: TTheme;
  showBreadcrumb: boolean;
  pageBackground: boolean;
}

type TTheme = 'dark' | 'light';

export const layout: ILayout = {
  theme: 'light', // 菜单风格, light, dark
  showBreadcrumb: true, // 展示面包屑导航
  pageBackground: true,
};

export const project = {
  title: '管理后台', // 标题
  openAuth: true, // openAuth权限校验 true：启用 false：关闭
};
