import { cloneDeep } from 'lodash';
import { menu, IMenuItem } from 'configs/menu';
import { project } from 'configs/global';

const proMenuCollapsed = 'pro-menu-collapsed';
let menuCollapsed: boolean;

export const getMenuCollapsed = () => {
  if (menuCollapsed !== undefined) {
    return menuCollapsed;
  }
  return !!localStorage.getItem(proMenuCollapsed);
};

export const saveMenuCollapsed = (collapsed: boolean) => {
  localStorage.setItem(proMenuCollapsed, collapsed ? '1' : '');
};

const getMenuMap = () => {
  const map = new Map<string, { current: IMenuItem; path: IMenuItem[] }>();
  const cachedPath: IMenuItem[] = [];

  const deepTraverse = (items: IMenuItem[]) => {
    for (const item of items) {
      const { path, children } = item;
      if (children) {
        cachedPath.push(item);
        deepTraverse(children);
      } else if (path) {
        map.set(path, { current: cloneDeep(item), path: cloneDeep(cachedPath) });
      }
    }
    cachedPath.pop();
  };

  deepTraverse(menu);
  return map;
};

export const getAuthMenu = (isAdmin: boolean): IMenuItem[] => {
  if (!project.openAuth) {
    return menu;
  }

  const isAuthMenu = (admin?: boolean): boolean => {
    if (admin === undefined) {
      return true;
    }
    return isAdmin === admin;
  };

  const authMenu: IMenuItem[] = [];
  menu.forEach((m) => {
    const menuItem = m;
    if (menuItem.path && isAuthMenu(menuItem.admin)) {
      authMenu.push(menuItem);
    } else if (menuItem.children && menuItem.children.length > 0) {
      menuItem.children = menuItem.children.filter((childMenu) => isAuthMenu(childMenu.admin));
      if (menuItem.children.length > 0) {
        authMenu.push(menuItem);
      }
    }
  });

  return authMenu;
};

export const mapedMenu = getMenuMap();
