import * as routerPath from "./routerPath";

export interface IMenuItem {
  key: string;
  label: string;
  path?: string;
  children?: IMenuItem[];
  admin?: boolean;
}

export const menu: IMenuItem[] = [
  {
    key: 'home',
    label: '首页',
    path: routerPath.home,
  },
  {
    key: 'admin',
    label: '登录管理',
    admin: true,
    path: routerPath.admin,
  },
  {
    key: 'bingliji',
    label: '病例集',
    path: routerPath.bingliji,
  },
  {
    key: 'calendar',
    label: '会议日历',
    path: routerPath.calendar,
  },
  {
    key: 'method',
    label: '使用方法',
    path: routerPath.method,
  },
  {
    key: 'category',
    label: '分类管理',
    children: [
      {
        key: 'mainCategory',
        label: '主分类管理',
        path: routerPath.category,
      },
      {
        key: 'subCategory',
        label: '子分类管理',
        path: routerPath.subCategory,
      },
    ],
  },
  {
    key: 'config',
    label: '配置管理',
    children: [
      {
        key: 'proConfig',
        label: '项目配置',
        path: routerPath.projectConfig,
      },
      {
        key: 'pageConfig',
        label: '页面配置',
        path: routerPath.pageConfig,
      },
    ],
  },
  {
    key: 'content',
    label: '内容管理',
    children: [
      {
        key: 'product',
        label: '产品管理',
        path: routerPath.product,
      },
      {
        key: 'article',
        label: '文章管理',
        path: routerPath.article,
      },
      {
        key: 'series',
        label: '课程系列',
        path: routerPath.series,
      },
      {
        key: 'course',
        label: '课程管理',
        path: routerPath.course,
      },
      {
        key: 'comment',
        label: '评论管理',
        path: routerPath.comment,
      },
    ],
  },
  {
    key: 'user',
    label: '用户管理',
    path: routerPath.User,
  },
  {
    label: '一级菜单',
    key: 'menu',
    children: [
      {
        key: 'sub_menu',
        label: '二级菜单',
        children: [
          {
            key: 'third_menu',
            label: '三级菜单',
            path: '/third-menu',
          },
        ],
      },
    ],
  },
];
