import React, {useState} from 'react';
import {Button, Modal, Skeleton, Space} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import EditConfigDrawer from './components/EditConfigDrawer';
import {fetchAdminList, fetchAdminDel, fetchAdminUpdate, fetchClearCache} from "../../services/config";
import {ColumnsType} from "antd/es/table";
import BlankRight from "../../components/BlankRight";
import {NConfig} from "../../types/config";
import {copyText} from "../../utils";
import {useAppSelector} from "../../modules/store";
import ErrorPage from "../../components/ErrorPage";
import SimpleTable, {ISimpleTableRef} from "../../components/SimpleTable";
import {baseRender} from "../../components/Render";

const AdminConfig = () => {
  const globalState = useAppSelector(state => state.global);
  const tableRef = React.createRef<ISimpleTableRef>();
  const [list, setList] = useState<NConfig.IAdminItem[]>([]);
  const [currentUpdate, setCurrentUpdate] = useState<NConfig.IAdminItem | true | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const fetchList = async () => {
    const data = await fetchAdminList();
    return {
      list: data,
      count: data.length,
    }
  };

  const handleDelUser = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchAdminDel(id);
        res  && await onRefreshList();
      },
      onCancel: () => {},
    })
  };
  const handleReset = async (record: NConfig.IAdminItem) => {
    Modal.confirm({
      title: '提示',
      content: '确定重置吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchAdminUpdate(record.id);
        res && copyText(`${record.username}\n${res.password}`, '重置成功，');
      },
      onCancel: () => {},
    })
  };

  const columns: ColumnsType<NConfig.IAdminItem> = [
    {
      title: '用户名',
      dataIndex: 'username',
      render: baseRender,
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button size='small' type='primary' onClick={() => handleReset(record)}>
            重置密码
          </Button>
          <Button size='small' danger type='primary' onClick={() => handleDelUser(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  if (globalState.loading) return <Skeleton />;
  if (!globalState.isAdmin) return <ErrorPage code='403' />

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <BlankRight>
        <Button type='primary' onClick={() => fetchClearCache()}>清缓存</Button>
        <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加用户</Button>
      </BlankRight>
      <SimpleTable columns={columns} fetchList={fetchList} ref={tableRef} pagination={false}/>
      <EditConfigDrawer
        visible={!!currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={() => onRefreshList()}
      ></EditConfigDrawer>
    </PageBox>
  );
};

export default AdminConfig;
