import { request } from 'utils';
import {message} from "antd";
import {PAGE_SIZE, SUCCESS_CODE} from "../configs";

export const fetchProConfigList = async () => {
  try {
    const res = await request.get('/config/projectConfigList');
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchProConfigCreate = async (values: any) => {
  try {
    const res = await request.post('/config/createProjectConfig', values);
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;  }
};

export const fetchProConfigDelete = async (id: number) => {
  try {
    const res = await request.post('/config/deleteProjectConfig', {
      id,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchProConfigModify = async (id: number, values: any) => {
  try {
    const res = await request.post('/config/modifyProjectConfig', {
      id,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchConfigList = async (page: number, pageKey: string = '') => {
  try {
    const res = await request.get(`/config/pageConfigList?page=${page}&pageSize=${PAGE_SIZE}&pageKey=${pageKey || ''}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchConfigCreate = async (values: any) => {
  try {
    const res = await request.post('/config/createPageConfig', values);
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchConfigDelete = async (id: number) => {
  try {
    const res = await request.post('/config/deletePageConfig', {
      id,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchConfigModify = async (id: number, values: any) => {
  try {
    const res = await request.post('/config/modifyPageConfig', {
      id,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};


export const uploadConfigFile = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = await request.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (e: any) {
    if (e.code) {
      return e;
    }
    message.error(e.msg);
  }
}

export const fetchAdminList = async () => {
  try {
    const res = await request.get(`/admin/adminList`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};
export const fetchAdminDel = async (id: number) => {
  try {
    const res = await request.post(`/admin/delete`, { id });
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};
export const fetchAdminCreate = async (username: string) => {
  try {
    const res = await request.post(`/admin/create`, { username });
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
/**
 * 重置密码
 * @param id
 */
export const fetchAdminUpdate = async (id: number) => {
  try {
    const res = await request.post(`/admin/update`, { id });
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

/**
 * 清除缓存
 */
export const fetchClearCache = async () => {
  try {
    const res = await request.post(`/common-config/clear`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
