import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {fetchUserInfo} from 'services/login';

const namespace = 'global';

export interface IAuth {
  id: number;
  name: string;
  auth_key: string;
  app_id: number;
}

export interface IGlobalState {
  loading: boolean;
  name: string;
  isAdmin: boolean;
  isLogin: boolean;
}

const initialState: IGlobalState = {
  loading: true,
  name: '',
  isAdmin: false,
  isLogin: false,
};

export const fetchAuth = createAsyncThunk(`${namespace}/userInfo`, async () => {
  const result = await fetchUserInfo();
  return {
    name: result.username,
    // isAdmin: false,
    isAdmin: result.isAdmin,
  };
});

// 创建带有命名空间的reducer
const counterSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAuth.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.name = payload?.name || 'ddddd';
        state.isLogin = !!payload.name;
        state.isAdmin = payload?.isAdmin || false;
      })
      .addCase(fetchAuth.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectGlobal = (state: RootState) => state.global;
export default counterSlice.reducer;
