import React, {useState} from 'react';
import {Button, Col, Form, Image, Input, Modal, Row, Space} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import EditConfigDrawer from './components/EditConfigDrawer';
import {fetchConfigList, fetchConfigDelete} from "../../services/config";
import {ColumnsType} from "antd/es/table";
import BlankRight from "../../components/BlankRight";
import {NConfig} from "../../types/config";
import SimpleTable, { ISimpleTableRef } from "../../components/SimpleTable";
import {baseRender, imageRender, toolTipRender} from "../../components/Render";

const PageConfig = () => {
  const [form] = Form.useForm();
  const tableRef = React.createRef<ISimpleTableRef>();
  const [currentUpdate, setCurrentUpdate] = useState<NConfig.IPageConfigItem | true | null>(null);

  const fetchList = async (page: number = 1) => {
    const { pageKey = '' } = form.getFieldsValue();
    const { list, count } = await fetchConfigList(page, pageKey);
    return {list, count};
  };

  const handleDel = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchConfigDelete(id);
        res  && onRefreshList();
      },
      onCancel: () => {},
    })
  };

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const columns: ColumnsType<NConfig.IPageConfigItem> = [
    {
      title: '页面标识',
      dataIndex: 'pageKey',
      render: baseRender,
    },
    {
      title: '页面名称',
      dataIndex: 'pageName',
      render: baseRender,
    },
    {
      title: '页面封面',
      dataIndex: 'pageCover',
      render: imageRender,
    },
    {
      title: '分享图片',
      dataIndex: 'shareCover',
      render: imageRender,
    },
    {
      title: '分享标题',
      dataIndex: 'shareTitle',
      render: toolTipRender,
    },
    {
      title: '排序类型',
      dataIndex: 'orderType',
      render: baseRender,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      render: toolTipRender,
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => setCurrentUpdate(record)}>
            编辑
          </Button>
          <Button danger type='primary' onClick={() => handleDel(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <Form form={form} name='advanced_search' style={{ background: '#fff', padding: '24px'}} onFinish={onRefreshList}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label='页面标识' name='pageKey'>
              <Input placeholder='请输入页面标识'></Input>
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: 'right' }}>
          <Space size='small'>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                onRefreshList();
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      </Form>
      <BlankRight>
        <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加数据</Button>
      </BlankRight>
      <SimpleTable
        ref={tableRef}
        columns={columns}
        fetchList={fetchList}
      />
      <EditConfigDrawer
        info={currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={onRefreshList}
      ></EditConfigDrawer>
    </PageBox>
  );
};

export default PageConfig;
