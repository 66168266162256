import React from 'react';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message, Upload, UploadProps, Image } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {uploadConfigFile} from "../../services/config";
import {RcFile} from "antd/lib/upload";
import {SUCCESS_CODE} from "../../configs";

const customRequest = async (options: UploadRequestOption) => {
  // @ts-ignore 设置 50% 进度
  options?.onProgress({ percent: 50 });
  const res = await uploadConfigFile(options.file as any);

  const { data, code, msg } = res;
  if (code !== SUCCESS_CODE || !data.url) {
    message.warn(msg);
    // @ts-ignore
    return options?.onError(new Error(msg));
  }

  const result = {
    uid: ((options.file) as RcFile).uid, // 注意，这个uid一定不能少，否则上传失败
    name: ((options.file) as RcFile).name,
    status: 'done',
    data: data.url, // 拿到上传后的原图地址
    percent: 100,
  }
  // @ts-ignore 执行onSuccess，触发下面的 onChange
  options?.onSuccess(result);
};

export enum UploadAcceptType {
  image = 'image/*',
  other = '*/*',
}

interface IUploadImage {
  onChange?: Function;
  value?: string;
  accept?: string;
}

const UploadSingle = (props: IUploadImage) => {
  const { accept = UploadAcceptType.image } = props;
  const setting: UploadProps = {
    name: 'file',
    data: {
      time: new Date().getTime(),
    },
    customRequest,
    maxCount: 1,
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'removed') {
        props.onChange?.('');
      }
      if (info.file.status === 'done') {
        props.onChange?.(info.file.response.data);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const uploadButton = (
    <div style={{ border: '1px dashed #ccc', padding: '13px 25px', textAlign: 'center' }}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  );

  const del = (e: any) => {
    e.stopPropagation();
    props.onChange?.('');
  };

  return (
    <Upload {...setting} accept={accept} >
      {props.value ? (
        <div style={{ position: 'relative' }}>
          <CloseCircleOutlined onClick={e => del(e)} style={{ position: 'absolute', left: 104, fontSize: 18 }} />
          {accept === UploadAcceptType.image ? (
            <Image src={props.value} preview={false} style={{ width: '100px' }} />
          ) : (
            <div>{props.value}</div>
          )}
        </div>
      ) : uploadButton}
    </Upload>
  );
};
export default UploadSingle;
