import React, {memo, PropsWithChildren, useEffect} from 'react';

import {Button, Drawer, Form, Input, Select, Space} from 'antd';
import {NConfig} from "../../../types/config";
import {fetchUserCreate, fetchUserUpdate} from "../../../services/user";

const deptOptions = [
  {
    label: '技术部',
    value: '1',
  },
  {
    label: '运营部',
    value: '2',
  }
]
interface IProps {
  info: NConfig.IPageConfigItem | true | null;
  onClose: Function;
  onRefresh: Function;
}

export default memo(({ info, onClose, onRefresh }: PropsWithChildren<IProps>) => {
  const [form] = Form.useForm();
  const onInnerClose = () => {
    form.setFieldsValue({
      name: '',
      value: '',
      url: '',
      image: '',
    });
    onClose();
  };

  useEffect(() => {
    if (info && typeof info === 'object') {
      form.setFieldsValue(info);
    }
  }, [info]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      let res;
      if (typeof info === 'object' && info && info.id) {
        res = await fetchUserUpdate(info.id, values);
      } else {
        res = await fetchUserCreate(values);
      }
      if (res) {
        await onRefresh();
        onInnerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  let UserInfo = null;
  if (info) {
    UserInfo = (
      <Form
        form={form}
        labelWrap
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        layout='horizontal'
      >
        <Form.Item label='用户名' name='userName' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='密码' name='password' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='手机号' name='mobile' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='部门' name='dept'>
          <Select placeholder='请输入' options={deptOptions} allowClear/>
        </Form.Item>
        <Form.Item label='职位' name='title'>
          <Input placeholder='请输入'/>
        </Form.Item>
      </Form>
    );
  }

  return (
    <Drawer
      title='编辑用户信息'
      width={1200}
      onClose={onInnerClose}
      destroyOnClose
      maskClosable={false}
      open={!!info}
      footer={(
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Space>
            <Button onClick={onInnerClose}>取消</Button>
            <Button htmlType='submit' onClick={onSubmit} type='primary'>
              确定
            </Button>
          </Space>
        </div>
      )}
    >
      {UserInfo}
    </Drawer>
  );
});
