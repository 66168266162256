import React from 'react';
import { BrowserRouterProps } from 'react-router-dom';
import LoginPage from "../pages/Login";
import ProConfig from "../pages/ProConfig";
import PageConfig from "../pages/PageConfig";
import * as routerPath from "./routerPath";
import HomePage from "../pages/Home";
import AdminConfig from "../pages/AdminConfig";
import InnerUser from "../pages/InnerUser";
import {CalendarConfig, CategoryConfig, BingLiJiConfig, MethodConfig, SeriesConfig} from "../pages/CommonConfig";
import SubCategoryConfig from "../pages/SubCategory";
import ProductPage from "../pages/Product";
import ArticlePage from "../pages/Article";
import CoursePage from "../pages/Course";
import CommentPage from "../pages/Comment";
import PasswordPage from "../pages/Password";

interface IRouteItem {
  admin?: boolean;
  path: string;
  exact?: boolean;
  Component: React.FC<BrowserRouterProps>;
}

export const routes: IRouteItem[] = [
  {
    path: routerPath.home,
    Component: HomePage,
    exact: true,
  },
  {
    path: routerPath.login,
    Component: LoginPage,
  },
  {
    path: routerPath.admin,
    Component: AdminConfig,
  },
  {
    path: routerPath.projectConfig,
    Component: ProConfig,
  },
  {
    path: routerPath.pageConfig,
    Component: PageConfig,
  },
  {
    path: routerPath.User,
    Component: InnerUser,
  },
  {
    path: routerPath.calendar,
    Component: CalendarConfig,
  },
  {
    path: routerPath.bingliji,
    Component: BingLiJiConfig,
  },
  {
    path: routerPath.category,
    Component: CategoryConfig,
  },
  {
    path: routerPath.method,
    Component: MethodConfig,
  },
  {
    path: routerPath.series,
    Component: SeriesConfig,
  },
  {
    path: routerPath.subCategory,
    Component: SubCategoryConfig,
  },
  {
    path: routerPath.product,
    Component: ProductPage,
  },
  {
    path: routerPath.article,
    Component: ArticlePage,
  },
  {
    path: routerPath.course,
    Component: CoursePage,
  },
  {
    path: routerPath.comment,
    Component: CommentPage,
  },
  {
    path: routerPath.password,
    Component: PasswordPage,
  },
];
