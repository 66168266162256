import React from 'react';
import { Layout, Space, Row } from 'antd';
import Style from './layout.module.less';

const { Footer } = Layout;

const LFooter = () => (
  <Footer className={Style.proFooter}>
    <Row justify='center'>
      <Space>
        power ©2024 Created by TaoZun technology
      </Space>
    </Row>
  </Footer>
);

export default React.memo(LFooter);
