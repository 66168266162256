import React from 'react';
import Style from './index.module.less';

const BlankRight = ({ children }: React.HTMLAttributes<HTMLElement>) => (
  <div className={Style.blankRight}>
    {children}
  </div>
);

export default React.memo(BlankRight);
