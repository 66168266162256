import React, {memo, PropsWithChildren, useEffect} from 'react';

import {Button, Drawer, Form, Input, InputNumber, Space} from 'antd';
import UploadSingle, {UploadAcceptType} from "../../../components/Form/UploadSingle";
import {fetchVideoModify, fetchVideoCreate } from "../../../services/content";
import {NBiz} from "../../../types/biz";
import FormEditor, {IEditorRef} from "../../../components/Form/FormEditor";

interface IProps {
  parentContentId?: number;
  info: NBiz.IContentItem | true | null;
  onClose: Function;
  onRefresh: Function;
}

export default memo((props: PropsWithChildren<IProps>) => {
  const { info, onClose, onRefresh } = props;
  const editRef = React.createRef<IEditorRef>();
  const [form] = Form.useForm<NBiz.IContentItem>();
  const onInnerClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (info && typeof info === 'object') {
      form.setFieldsValue(info);
      editRef.current?.setContent(info.content)
    }
  }, [info]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      const body = {
        ...values,
        parentContentId: props.parentContentId,
      }
      let res;
      if (typeof info === 'object' && info && info.id) {
        res = await fetchVideoModify(info.id, body);
      } else {
        res = await fetchVideoCreate(body);
      }
      if (res) {
        await onRefresh();
        onInnerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  let UserInfo = null;
  if (info) {
    UserInfo = (
      <Form
        form={form}
        labelWrap
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        layout='horizontal'
      >
        <Form.Item label='标题' name='title' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='封面' name='cover' rules={[{required: true, message: '请输入'}]}>
          <UploadSingle />
        </Form.Item>
        <Form.Item label='位置' name='position'>
          <InputNumber placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='视频' name='videoUrl' rules={[{ required: true, message: '请输入' }]}>
          <UploadSingle accept={UploadAcceptType.other} />
        </Form.Item>
        <Form.Item label='讲师介绍' name='content' rules={[{ required: true, message: '请输入' }]}>
          <FormEditor ref={editRef}/>
        </Form.Item>
      </Form>
    );
  }

  return (
    <Drawer
      title='编辑配置'
      width={1200}
      onClose={onInnerClose}
      destroyOnClose
      maskClosable={false}
      open={!!info}
      footer={(
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Space>
            <Button onClick={onInnerClose}>取消</Button>
            <Button htmlType='submit' onClick={onSubmit} type='primary'>
              确定
            </Button>
          </Space>
        </div>
      )}
    >
      {UserInfo}
    </Drawer>
  );
});
