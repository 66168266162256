import { request } from 'utils';
import {message} from "antd";
import {PAGE_SIZE, SUCCESS_CODE} from "../configs";
export const fetchProductList = async (page: number, title?: string) => {
  try {
    const res = await request.get(`/content/product-list?page=${page}&pageSize=${PAGE_SIZE}&title=${title}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchArticleList = async (page: number, parentContentId?: number, title?: string) => {
  try {
    const res = await request.get(`/content/article-list?page=${page}&pageSize=${PAGE_SIZE}&parentContentId=${parentContentId || ''}&title=${title}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};
export const fetchCourseList = async (page: number, title?: string) => {
  try {
    const res = await request.get(`/content/course-list?page=${page}&pageSize=${PAGE_SIZE}&title=${title}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};
export const fetchVideoList = async (page: number, parentContentId?: number, title?: string) => {
  try {
    const res = await request.get(`/content/video-list?page=${page}&pageSize=${PAGE_SIZE}&parentContentId=${parentContentId || ''}&title=${title}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchProductCreate = async (values: any) => {
  try {
    const res = await request.post('/content/product-create', values);
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
export const fetchArticleCreate = async (values: any) => {
  try {
    const res = await request.post('/content/article-create', values);
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
export const fetchCourseCreate = async (values: any) => {
  try {
    const res = await request.post('/content/course-create', values);
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
export const fetchVideoCreate = async (values: any) => {
  try {
    const res = await request.post('/content/video-create', values);
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchContentDelete = async (id: number) => {
  try {
    const res = await request.post('/content/delete', {
      id,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchProductModify = async (id: number, values: any) => {
  try {
    const res = await request.post('/content/product-update', {
      id,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
export const fetchArticleModify = async (id: number, values: any) => {
  try {
    const res = await request.post('/content/article-update', {
      id,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
export const fetchCourseModify = async (id: number, values: any) => {
  try {
    const res = await request.post('/content/course-update', {
      id,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
export const fetchVideoModify = async (id: number, values: any) => {
  try {
    const res = await request.post('/content/video-update', {
      id,
      ...values,
    });
    return res.code === SUCCESS_CODE;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
