import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Menu, MenuProps } from 'antd';
import { useAppSelector } from 'modules/store';
import { selectGlobal } from 'modules/global';
import { layout, IMenuItem } from 'configs';
import { mapedMenu, getAuthMenu } from 'utils/layout';

const { theme } = layout;
const getMenuItems: (menu: IMenuItem[]) => MenuProps['items'] = (menu: IMenuItem[]) =>
  menu.map((item) => {
    const { key, children, label, path } = item;
    const hasChild = children && children.length > 0;
    return {
      key,
      label: hasChild ? label : <Link to={path as string}>{label}</Link>,
      children: hasChild ? getMenuItems(children) : undefined,
    }
  });

interface ILMenuProps extends MenuProps {
  collapsed: boolean;
}

const LMenu = (props: ILMenuProps) => {
  const { collapsed, ...otherProps } = props;
  const { pathname } = useLocation();
  const selectedMap = mapedMenu.get(pathname);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const globalState = useAppSelector(selectGlobal);
  const menu = useMemo(() => getAuthMenu(globalState.isAdmin), [globalState.isAdmin]);

  const selectedKeys = useMemo(() => {
    if (selectedMap) {
      return [selectedMap.current.key];
    }
    return [];
  }, [pathname]);

  useEffect(() => {
    if (selectedMap) {
      const { path } = selectedMap;
      if (!collapsed) setOpenKeys(path.map((p) => p.key));
    }
  }, [collapsed]);

  return (
    <Menu
      {...otherProps}
      onOpenChange={(openKeys) => setOpenKeys(openKeys as string[])}
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      theme={theme}
      items={getMenuItems(menu)}
    />
  );
};

export default React.memo(LMenu);
