import {NConfig} from "../../types/config";
import {Button, Form, Image, Input, Modal, Select, Space, Table} from "antd";
import React from "react";
import {ColumnsType} from "antd/es/table";
import BlankRight from "../BlankRight";
import UploadSingle from "./UploadSingle";
import {URL_TYPE_OPTIONS} from "../../configs";

interface IBannerList {
  onChange?: Function;
  value?: NConfig.IBanner[];
}

const FormBanner = (props: IBannerList) => {
  const { onChange, value} = props;
  const [form] = Form.useForm();
  const [showModal, setShowModal] = React.useState(false);

  const handleDel = (index: number) => {
    const newValue = value?.slice() || [];
    newValue.splice(index, 1);
    onChange?.(newValue);
  }

  const handleSubmit = async () => {
    try {
      const values = form.getFieldsValue();
      const newValue = value?.slice() || [];
      newValue.push({
        title: values.title || '',
        url: values.url || '',
        image: values.image || '',
        type: values.type || '',
      });
      onChange?.(newValue);
      form.resetFields();
      setShowModal(false);
    } catch {}
  };

  const columns: ColumnsType<NConfig.IBanner> = [
    {
      title: '标题',
      dataIndex: 'title',
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (value) => {
        if (!value) return '--';
        return URL_TYPE_OPTIONS.find((item) => item.value === value)?.label || '--';
      }
    },
    {
      title: '跳转链接',
      dataIndex: 'url',
    },
    {
      title: '图片',
      dataIndex: 'image',
      render: (value) => {
        if (value) {
          return <Image width={100} src={value} />
        }
        return '--';
      }
    },
    {
      title: '操作',
      fixed: 'right',
      render: (_text: string, _record, index) => (
        <Space>
          <Button danger size='small' type='primary' onClick={() => handleDel(index)}>
            删除
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <BlankRight>
        <Button size='small' type='primary' onClick={() => setShowModal(true)}>添加数据</Button>
      </BlankRight>
      <Table size='small' columns={columns} dataSource={value} rowKey='title'/>
      <Modal
        title='添加数据'
        open={showModal}
        onOk={handleSubmit}
        onCancel={() => setShowModal(false)}
      >
        <Form
          form={form}
          labelWrap
          labelCol={{span: 6}}
          wrapperCol={{span: 14}}
          layout='horizontal'
          size='small'
        >
          <Form.Item label='标题' name='title' rules={[{required: true, message: '请输入'}]}>
            <Input placeholder='请输入'/>
          </Form.Item>
          <Form.Item label='跳转类型' name='type'>
            <Select placeholder='请输入跳转地址' options={URL_TYPE_OPTIONS} allowClear/>
          </Form.Item>
          <Form.Item label='跳转地址' name='url'>
            <Input placeholder='请输入跳转地址'/>
          </Form.Item>
          <Form.Item label='图片' name='image'>
            <UploadSingle />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default FormBanner;
