import { request } from 'utils';
import {message} from "antd";
import {PAGE_SIZE} from "../configs";

export const fetchUserList = async (page: number, username: string) => {
  try {
    const res = await request.get(`/user/userList?page=${page}&pageSize=${PAGE_SIZE}&username=${username || ''}`);
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};
export const fetchUserDel = async (id: number) => {
  try {
    const res = await request.post(`/user/deleteUser`, { id });
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
  }
};
export const fetchUserCreate = async (username: string) => {
  try {
    const res = await request.post(`/user/createUser`, { username });
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};

export const fetchUserUpdate = async (id: number, values: any) => {
  try {
    const res = await request.post(`/user/modifyUser`, { id, ...values });
    return res.data;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
