import queryString from 'query-string';
import { request } from 'utils';
import {message} from "antd";

export const fetchUserInfo = async () => {
  try {
    const res = await request.get('/admin/checkLogin');
    return res.data;
  } catch (e) {
    if (location.pathname !== '/login') {
      window.location.href = '/login?redirect=' + location.pathname;
    }
  }
};

export const fetchLoginApi = async (values: { username: string, password: string}) => {
  try {
    await request.post('/admin/login', {
      username: values.username,
      password: values.password,
    });
    const { redirect = ''} = queryString.parse(location.search);
    location.href = redirect ? redirect as string : '/';
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchLogoutApi = async () => {
  try {
    await request.get('/admin/logout');
    const pathname = location.pathname;
    location.href = '/login?redirect=' + pathname;
  } catch (e: any) {
    message.error(e.msg);
  }
};

export const fetchModifyPassword = async (old: string, newpass: string) => {
  try {
    await request.post(`/admin/password`, {
      newPassword: newpass,
      oldPassword: old,
    });
    return true;
  } catch (e: any) {
    message.error(e.msg);
    return false;
  }
};
