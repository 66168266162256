import React from 'react';
import Breadcrumb from 'components/Breadcrumb';
import style from './index.module.less';

const HomePage = () => {

  return (
    <div className={style.homeCard}>
      <Breadcrumb />
      <div className={style.title}>
        欢迎使用后台管理系统
      </div>
    </div>
  );
};

export default React.memo(HomePage);
