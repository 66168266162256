import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { routes } from 'configs';
import AuthorizedRoute from 'components/AuthorizedRoute';
import UnAuthorized from 'pages/Error/403';
import NotFound from 'pages/Error/404';
import ErrorPage from 'pages/Error/500';

import Style from './layout.module.less';

const { Content } = Layout;

const LContent = () => (
  <Content className={Style.proContent}>
    <Switch>
      {routes.map((route, index) => (
        <AuthorizedRoute
          key={index}
          admin={route.admin}
          exact={route.exact}
          path={route.path}
          component={route.Component}
        />
      ))}
      <Route path='/403' component={UnAuthorized} />
      <Route path='/500' component={ErrorPage} />
      <Route path='*' component={NotFound} />
    </Switch>
  </Content>
);

export default React.memo(LContent);
