import React from 'react';
import {Button, Col, Form, Input, Modal, Row, Space} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import {ColumnsType} from "antd/es/table";
import {NConfig} from "../../types/config";
import SimpleTable, { ISimpleTableRef } from "../../components/SimpleTable";
import {baseRender, findRender, timeRender, toolTipRender} from "../../components/Render";
import {AUDIT_STATUS} from "../../configs";
import {fetchCommentAudit, fetchCommentDelete, fetchCommentList} from "../../services/comment";

const CommentPage = () => {
  const [form] = Form.useForm();
  const tableRef = React.createRef<ISimpleTableRef>();

  const fetchList = async (page: number = 1) => {
    const { userName = '', content = '' } = form.getFieldsValue();
    const { list, count } = await fetchCommentList(page, userName, content);
    return {list, count};
  };

  const handleDel = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchCommentDelete(id);
        res  && onRefreshList();
      },
      onCancel: () => {},
    })
  };

  const handleAudit = async (id: number) => {
    Modal.confirm({
      content: '请选择审核操作？',
      okText: '通过',
      cancelText: '拒绝',
      onOk: async () => {
        const res = await fetchCommentAudit(id, true);
        res  && onRefreshList();
      },
      onCancel: async () => {
        const res = await fetchCommentAudit(id, false);
        res  && onRefreshList();
      },
    })
  };

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const columns: ColumnsType<NConfig.IPageConfigItem> = [
    {
      title: '留言',
      dataIndex: 'pageKey',
      render: toolTipRender,
    },
    {
      title: '文章',
      dataIndex: 'contentId',
      render: baseRender,
    },
    {
      title: '留言时间',
      dataIndex: 'create_at',
      render: timeRender,
    },
    {
      title: '留言人',
      dataIndex: 'createdBy',
      render: baseRender,
    },
    {
      title: '审核状态',
      dataIndex: 'auditStatus',
      render: findRender(AUDIT_STATUS),
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => handleAudit(record.id)}>
            审核
          </Button>
          <Button danger type='primary' onClick={() => handleDel(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <Form form={form} name='advanced_search' style={{ background: '#fff', padding: '24px'}} onFinish={onRefreshList}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label='评论人' name='userName'>
              <Input placeholder='请输入'></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='评论内容' name='content'>
              <Input placeholder='请输入'></Input>
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: 'right' }}>
          <Space size='small'>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                onRefreshList();
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      </Form>
      <SimpleTable
        ref={tableRef}
        columns={columns}
        fetchList={fetchList}
      />
    </PageBox>
  );
};

export default CommentPage;
