import {NBiz} from "../../../types/biz";
import {Button, Col, Form, Input, Modal, Row, Space} from "antd";
import React, {useState} from "react";
import SimpleTable, {ISimpleTableRef} from "../../../components/SimpleTable";
import {fetchVideoList, fetchContentDelete} from "../../../services/content";
import {ColumnsType} from "antd/es/table";
import PageBox from "../../../components/PageBox";
import BlankRight from "../../../components/BlankRight";
import {baseRender, imageRender, timeRender, toolTipRender} from "../../../components/Render";
import EditVideoDrawer from "./EditVideoDrawer";

interface IProps {
  info: NBiz.IContentItem;
}

const EditTable = (props: IProps) => {
  const [form] = Form.useForm();
  const tableRef = React.createRef<ISimpleTableRef>();
  const [currentUpdate, setCurrentUpdate] = useState<NBiz.IContentItem | true | null>(null);

  const fetchList = async (page: number = 1) => {
    const { title = '' } = form.getFieldsValue();
    const { list, count } = await fetchVideoList(page, props.info.id, title);
    return {list, count};
  };

  const handleDel = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchContentDelete(id);
        res  && onRefreshList();
      },
      onCancel: () => {},
    })
  };

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const columns: ColumnsType<NBiz.IContentItem> = [
    {
      title: '标题',
      dataIndex: 'title',
      render: toolTipRender,
    },
    {
      title: '封面',
      dataIndex: 'cover',
      render: imageRender,
    },
    {
      title: '链接',
      dataIndex: 'videoUrl',
      render: baseRender,
    },
    {
      title: '位置',
      dataIndex: 'position',
      render: baseRender,
    },
    {
      title: '创建时间',
      dataIndex: 'create_at',
      render: timeRender
    },
    {
      title: '创建人',
      dataIndex: 'createdBy',
      render: baseRender,
    },
    {
      title: '操作',
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => setCurrentUpdate(record)}>
            编辑
          </Button>
          <Button danger type='primary' onClick={() => handleDel(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <PageBox>
      <Form form={form} name='advanced_search' style={{ background: '#fff', padding: '24px'}} onFinish={onRefreshList}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label='标题' name='title'>
              <Input placeholder='请输入'></Input>
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: 'right' }}>
          <Space size='small'>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                onRefreshList();
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      </Form>
      <BlankRight>
        <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加数据</Button>
      </BlankRight>
      <SimpleTable
        ref={tableRef}
        columns={columns}
        fetchList={fetchList}
      />
      <EditVideoDrawer
        parentContentId={props.info.id}
        info={currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={onRefreshList}
      ></EditVideoDrawer>
    </PageBox>
  );
};


export default EditTable;
