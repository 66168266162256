import React from 'react';
import {Button, Form, Input, Card, message} from 'antd';
import Breadcrumb from 'components/Breadcrumb';
import style from './index.module.less';
import {fetchLogoutApi, fetchModifyPassword} from "../../services/login";

const { useForm } = Form;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const PasswordPage = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form] = useForm();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const {old, newPass, repeat} = values;
      if (newPass !== repeat) {
        message.error('两次密码不一致');
        return;
      }
      const res = await fetchModifyPassword(old, newPass);
      if (res) {
        message.success('修改成功');
        await fetchLogoutApi();
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Breadcrumb />
      <Card className={style.passCard}>
        <Form {...layout} form={form} name='loginForm' onFinish={handleSubmit}>
          <Form.Item name='old' label='旧密码' rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name='newPass' label='新密码' rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name='repeat' label='确认新密码' rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button id='btnSubmit' type='primary' htmlType='submit' loading={loading}>
              提交修改
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default React.memo(PasswordPage);
