export const home = '/';
export const login = '/login';
export const admin = '/admin';
export const password = '/password';
export const projectConfig = '/pro-config';
export const pageConfig = '/page-config';
export const User = '/user';

export const bingliji = '/bingliji';
export const product = '/product';
export const article = '/article';
export const course = '/course';
export const comment = '/message';
export const calendar = '/calendar';
export const category = '/category';
export const method = '/method';
export const series = '/series';
export const subCategory = '/sub-category';
