import React, {memo, PropsWithChildren, useEffect} from 'react';

import {Button, Drawer, Form, Input, Select, Space} from 'antd';
import UploadSingle, {UploadAcceptType} from "../../../components/Form/UploadSingle";
import {fetchArticleModify, fetchArticleCreate } from "../../../services/content";
import {NBiz} from "../../../types/biz";
import {fetchCommonConfigList} from "../../../services/commonConfig";
import {CommonConfig, CONTENT_TYPE, ContentType} from "../../../configs";
import {fetchSubCategoryList} from "../../../services/category";
import {DefaultOptionType} from "rc-select/lib/Select";
import FormEditor from "../../../components/Form/FormEditor";

const getCateGoryOptions = async () => {
  const res = await fetchCommonConfigList(CommonConfig.category);
  const list: DefaultOptionType[] = [];
  res.forEach((item: any) => {
    if (item.enName !== 'dashi') {
      list.push({label: item.name, value: item.enName });
    }
  });
  return list;
};
const getMethodOptions = async () => {
  const res = await fetchCommonConfigList(CommonConfig.method);
  const list: DefaultOptionType[] = [];
  res.forEach((item: any) => {
    list.push({label: item.name, value: item.enName });
  });
  return list;
};

const getSubCateGoryOptions = async (type: string) => {
  const res = await fetchSubCategoryList(type);
  const list: DefaultOptionType[] = [];
  res.forEach((item: any) => {
    list.push({label: item.title, value: item.id.toString() });
  });
  return list;
};

interface IProps {
  isProduct: boolean;
  parentContentId?: number;
  categoryEnName?: string;
  subCategoryId?: string;
  info: NBiz.IContentItem | true | null;
  onClose: Function;
  onRefresh: Function;
}

export default memo((props: PropsWithChildren<IProps>) => {
  const { info, onClose, onRefresh, isProduct, parentContentId } = props;
  const [methodOptions, setMethodOptions] = React.useState<DefaultOptionType[]>([]);
  const [categoryOptions, setCategoryOptions] = React.useState<DefaultOptionType[]>([]);
  const [subCategoryOptions, setSubCategoryOptions] = React.useState<DefaultOptionType[]>([]);
  const [form] = Form.useForm<NBiz.IContentItem>();
  const onInnerClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    getCateGoryOptions().then((options) => {
      setCategoryOptions(options);
    });
    isProduct && getMethodOptions().then((options) => {
      setMethodOptions(options);
    });
  }, []);

  useEffect(() => {
    if (info && typeof info === 'object') {
      form.setFieldsValue(info);
      getSubCateGoryOptions(info.categoryEnName).then(options => {
        setSubCategoryOptions(options);
      });
    }
  }, [info]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      let res;
      const body = {
        ...values,
        parentContentId: parentContentId || 0,
        categoryEnName: values.categoryEnName ?? props.categoryEnName,
        subCategoryId: values.subCategoryId ?? props.subCategoryId,
      };
      if (typeof info === 'object' && info && info.id) {
        res = await fetchArticleModify(info.id, body);
      } else {
        res = await fetchArticleCreate(body);
      }
      if (res) {
        await onRefresh();
        onInnerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeCategory = async (value: string) => {
    form.setFieldsValue({
      subCategoryId: undefined,
    });
    const options = await getSubCateGoryOptions(value);
    setSubCategoryOptions(options);
  };

  let UserInfo = null;
  if (info) {
    UserInfo = (
      <Form
        form={form}
        labelWrap
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        layout='horizontal'
      >
        <Form.Item label='标题' name='title' rules={[{required: true, message: '请输入'}]}>
          <Input placeholder='请输入'/>
        </Form.Item>
        <Form.Item label='封面' name='cover' rules={[{required: true, message: '请输入'}]}>
          <UploadSingle />
        </Form.Item>
        {isProduct  && (
          <Form.Item label='使用方法' name='method' rules={[{required: true, message: '请输入'}]}>
            <Select placeholder='请输入' options={methodOptions}/>
          </Form.Item>
        )}
        {!isProduct && (
          <>
            <Form.Item label='所属分类' name='categoryEnName' rules={[{required: true, message: '请输入'}]}>
              <Select placeholder='请输入' options={categoryOptions} onChange={value => {
                onChangeCategory(value);
              }}/>
            </Form.Item>
            <Form.Item label='所属子分类' name='subCategoryId' rules={[{required: true, message: '请输入'}]}>
              <Select placeholder='请输入' options={subCategoryOptions}/>
            </Form.Item>
          </>
        )}
        <Form.Item label='文章类型' name='articleContentType' rules={[{required: true, message: '请输入'}]}>
          <Select placeholder='请输入' options={CONTENT_TYPE}/>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(pre, cur) => pre.articleContentType !== cur.articleContentType}>
          {(form) => {
            const articleContentType = form.getFieldValue('articleContentType');
            if (articleContentType === ContentType.pdf) {
              return (
                <Form.Item label='PDF文件' name='url' rules={[{ required: true, message: '请输入' }]}>
                  <UploadSingle accept={UploadAcceptType.other} />
                </Form.Item>
              );
            } else if (articleContentType === ContentType.weixin) {
              return (
                <Form.Item label='微信文章地址' name='url' rules={[{ required: true, message: '请输入' }]}>
                  <Input placeholder='请输入'/>
                </Form.Item>
              );
            } else if (articleContentType === ContentType.video) {
              return (
                <Form.Item label='视频文件' name='videoUrl' rules={[{ required: true, message: '请输入' }]}>
                  <UploadSingle accept={UploadAcceptType.other} />
                </Form.Item>
              );
            } else if (articleContentType === ContentType.article) {
              return (
                <Form.Item label='文章内容' name='content' rules={[{ required: true, message: '请输入' }]}>
                  <FormEditor />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
      </Form>
    );
  }

  return (
    <Drawer
      title='编辑配置'
      width={1200}
      onClose={onInnerClose}
      destroyOnClose
      maskClosable={false}
      open={!!info}
      footer={(
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <Space>
            <Button onClick={onInnerClose}>取消</Button>
            <Button htmlType='submit' onClick={onSubmit} type='primary'>
              确定
            </Button>
          </Space>
        </div>
      )}
    >
      {UserInfo}
    </Drawer>
  );
});
