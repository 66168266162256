import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import Content from './Content';
import Sider from './Sider';
import Footer from './Footer';
import Style from './layout.module.less';

const Container = React.memo(() => (
  <Layout>
    <Header />
    <Layout>
      <Sider />
      <Layout className={Style.proContentLayout}>
        <Content />
        <Footer />
      </Layout>
    </Layout>
  </Layout>
));

export default Container
