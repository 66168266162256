import React from "react";
import {Image, Tooltip} from "antd";
import {getFormattedDate} from "../../utils";
import {DefaultOptionType} from "rc-select/lib/Select";

export const baseRender = (value: string) => {
  return (
    <div style={{ maxWidth: '200px'}}>{value}</div>
  );
}

export const imageRender = (value: string) => {
  if (!value) return '--';
  return (
    <Image width={'100px'} height={'100px'} src={value} />
  );
}

export const toolTipRender = (value: string) => {
  if (!value) return '--';
  return (
    <Tooltip title={value}>
      <div style={{maxWidth: '200px'}}>{value.substr(0, 20)}</div>
    </Tooltip>
  );
}
export const timeRender = (value: string) => {
  if (!value) return '--';
  return (
    <div style={{maxWidth: '200px'}}>{getFormattedDate(value)}</div>
  );
};

export const findRender = (options: DefaultOptionType[]) => {
  const optionMap: Record<string, string> = options.reduce((acc, cur) => {
    // @ts-ignore
    acc[cur.value] = cur.label;
    return acc;
  }, {});
  return (value: string) => {
    return (
      <div style={{maxWidth: '200px'}}>{optionMap[value] || value}</div>
    );
  };
}
