import React, {useState} from 'react';
import {Button, Col, Drawer, Form, Image, Input, Modal, Row, Space} from 'antd';
import PageBox from 'components/PageBox';
import Breadcrumb from 'components/Breadcrumb';
import EditConfigDrawer from './components/EditConfigDrawer';
import EditTable from './components/EditTable';
import {ColumnsType} from "antd/es/table";
import BlankRight from "../../components/BlankRight";
import SimpleTable, { ISimpleTableRef } from "../../components/SimpleTable";
import {fetchProductList, fetchContentDelete} from "../../services/content";
import {NBiz} from "../../types/biz";
import {baseRender, imageRender, timeRender, toolTipRender} from "../../components/Render";

const ProductPage = () => {
  const categoryEnName = 'xueshu';
  const [form] = Form.useForm();
  const tableRef = React.createRef<ISimpleTableRef>();
  const [currentUpdate, setCurrentUpdate] = useState<NBiz.IContentItem | true | null>(null);
  const [tableUpdate, setTabletUpdate] = useState<NBiz.IContentItem | null>(null);

  const fetchList = async (page: number = 1) => {
    const { title = '' } = form.getFieldsValue();
    const { list, count } = await fetchProductList(page, title);
    return {list, count};
  };

  const onRefreshList = () => {
    tableRef.current?.refreshList();
  };

  const handleDel = async (id: number) => {
    Modal.confirm({
      title: '提示',
      content: '确定删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await fetchContentDelete(id);
        res  && onRefreshList();
      },
      onCancel: () => {},
    })
  };

  const handleEdit = async (record: NBiz.IContentItem) => {
    setCurrentUpdate(record);
  }

  const handleTableEdit = async (record: NBiz.IContentItem) => {
    setTabletUpdate(record);
  }

  const columns: ColumnsType<NBiz.IContentItem> = [
    {
      title: '标题',
      dataIndex: 'title',
      render: toolTipRender,
    },
    {
      title: '封面',
      dataIndex: 'cover',
      render: imageRender,
    },
    {
      title: '产品分类',
      dataIndex: 'subCategoryName',
      render: baseRender,
    },
    {
      title: '链接',
      dataIndex: 'url',
      render: baseRender,
    },
    {
      title: '创建时间',
      dataIndex: 'create_at',
      render: timeRender,
    },
    {
      title: '创建人',
      dataIndex: 'createdBy',
      render: baseRender,
    },
    {
      title: '操作',
      width: 200,
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type='primary' onClick={() => handleTableEdit(record)}>
            关联内容
          </Button>
          <Button type='primary' onClick={() => handleEdit(record)}>
            编辑
          </Button>
          <Button danger type='primary' onClick={() => handleDel(record.id)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <PageBox>
      <Breadcrumb></Breadcrumb>
      <Form form={form} name='advanced_search' style={{ background: '#fff', padding: '24px'}} onFinish={onRefreshList}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label='标题' name='title'>
              <Input placeholder='请输入'></Input>
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: 'right' }}>
          <Space size='small'>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                onRefreshList();
              }}
            >
              重置
            </Button>
          </Space>
        </div>
      </Form>
      <BlankRight>
        <Button type='primary' onClick={() => setCurrentUpdate(true)}>添加数据</Button>
      </BlankRight>
      <SimpleTable
        ref={tableRef}
        columns={columns}
        fetchList={fetchList}
      />
      <EditConfigDrawer
        categoryEnName={categoryEnName}
        info={currentUpdate}
        onClose={() => setCurrentUpdate(null)}
        onRefresh={onRefreshList}
      ></EditConfigDrawer>
      <Drawer
        width={1200}
        open={!!tableUpdate}
        destroyOnClose
        onClose={() => setTabletUpdate(null)}
      >
        {tableUpdate && (
          <EditTable
            categoryEnName={categoryEnName}
            info={tableUpdate}
          ></EditTable>
        )}
      </Drawer>
    </PageBox>
  );
};

export default ProductPage;
